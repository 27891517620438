import React, { useEffect, useState } from 'react'
import logo from "../../images/ict-logo.png"
import mrSocFace from "../../images/mr-soc-face.svg"
import goldBell from "../../images/gold-bell.svg"
import "./Nav.css"
import { createTheme, ThemeProvider } from "@mui/material/styles"
import { Link, useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"
import axios from "axios"
import creamArrowRight from "../../images/cream-arrow-right.svg"
import whiteArrowRight from "../../images/white-arrow-right.svg"
import arrowDown from "../../images/arrow-down.svg"
import DoubleArrowRoundedIcon from '@mui/icons-material/DoubleArrowRounded';
import { Button } from '@mui/material'
import ReactGa from "react-ga4"



const Nav = () => {

    // USESTATES

    const [activeNav, setActiveNav] = useState(false)
    const [switchBurger, setSwitchBurger] = useState(false)

    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    // INITIALIZATIONS

    const theme = createTheme({
        palette: {
            primary: {
                main: "#27AAE1"
            },
            secondary: {
                main: "#2E3092"
            }
        }
    })

    const navigate = useNavigate();
    const dispatch = useDispatch();


    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    // CHANGE NAV BACKGROUND ON SCROLL

    const changeNavBg = () => {
        if (window.scrollY >= 50) {
            setActiveNav(true)
            setSwitchBurger(true)
        } else {
            setActiveNav(false)
            setSwitchBurger(false)
        }
    }


    window.addEventListener('scroll', changeNavBg)

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    // HOVER NAV DROPDOWN


    const dropSolutionsActive = () => {
        const dropMenu = document.querySelector('.nav-container .top-nav .logo-ul .top-nav-ul li.solutions-li .dropdown-container')
        console.log("Drop solutions dropdown")
        dropMenu.classList.add('active');
    }

    const dropSolutionsInactive = () => {
        const dropMenu = document.querySelector('.nav-container .top-nav .logo-ul .top-nav-ul li.solutions-li .dropdown-container')
        console.log("Disappear solutions dropdown")
        dropMenu.classList.remove('active');
    }

    const dropTrainingsActive = () => {
        const dropMenu = document.querySelector('.nav-container .top-nav .logo-ul .top-nav-ul li.trainings-li .dropdown-container')
        dropMenu.classList.add('active');
    }

    const dropTrainingsInactive = () => {
        const dropMenu = document.querySelector('.nav-container .top-nav .logo-ul .top-nav-ul li.trainings-li .dropdown-container')
        dropMenu.classList.remove('active');
    }

    const dropSubActive = (classname) => {
        const dropSubMenu = document.querySelector(`.nav-container .top-nav .logo-ul .top-nav-ul li .dropdown-container .dropdown-wrapper .main-ul .main-li.${classname} .sub-dropdown`)

        dropSubMenu.classList.add('active');
    }

    const dropSubInactive = (classname) => {
        const dropSubMenu = document.querySelector(`.nav-container .top-nav .logo-ul .top-nav-ul li .dropdown-container .dropdown-wrapper .main-ul .main-li.${classname} .sub-dropdown`)

        dropSubMenu.classList.remove('active');
    }


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    // FUNCTIONS

    const burgerToggle = () => {

        const burger = document.querySelector('.nav-container .top-nav .burger')
        const ulDiv = document.querySelector('.nav-container .ul-div');
        const ulDivContainer = document.querySelector('.nav-container .ul-div .ul-div-container');
        const nav_links = document.querySelectorAll('.nav-container .ul-div .ul-div-container ul li');
        const navUl = document.querySelector('.nav-container .ul-div .ul-div-container ul');




        ulDiv.classList.toggle('active-ul-div')
        ulDivContainer.classList.toggle('active-ul-div-container')
        navUl.classList.toggle('active-nav-ul')


        nav_links.forEach((link, index) => {
            if (link.style.animation) {
                link.style.animation = '';
            } else {
                link.style.animation = `nav-link-fade 0.5s ease forwards`; //${index / 7 + 0.3}s
            }
        });

        burger.classList.toggle('burger-toggle')


    }

    const dropCourses = () => {
        const courseDropdown = document.querySelector('.courses-dropdown-container')

        courseDropdown.classList.toggle('active')
    }

    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    return (
        <ThemeProvider theme={theme}>
            <nav className={activeNav ? 'nav-container active' : 'nav-container'}>

                <div className="ribbon-container">
                    <div className="ribbon">
                        <h1 className="ribbon-h1">Offers</h1>

                        <p className="ribbon-p">Get Up To <span>30% Discount</span> on ITIL4, PRINCE2 Agile & CBAP</p>

                        <div className="button-div">
                            <DoubleArrowRoundedIcon />
                            <Button variant='contained' color="primary" onClick={() => { navigate('/offers'); ReactGa.event("click_offer_ribbon") }}>Check Them Out</Button>
                        </div>
                    </div>
                </div>

                <div className="top-nav">
                    <div className="logo-ul">
                        <Link to="/"><img src={logo} className="logo" alt="ICT Mentors Logo" loading='lazy' /></Link>

                        <ul className="top-nav-ul">
                            <li><Link to="/" className='major-a'>Home</Link></li>
                            <li><Link to="/about" className='major-a'>About</Link></li>
                            <li className='courses-li solutions-li' onMouseEnter={dropSolutionsActive} onMouseLeave={dropSolutionsInactive}><Link to="/solutions" className='major-a'>Solutions</Link>

                                <div className="dropdown-container" onMouseEnter={dropSolutionsActive} onMouseLeave={dropSolutionsInactive}>
                                    <div className="dropdown-wrapper">
                                        <ul className="main-ul">
                                            <li className='main-li'><Link to="/strategy_formulation" className='long-a main-a'>IT Strategy Formulation</Link></li>
                                            <li className='main-li li-2'><Link to="/soc" className='long-a main-a'>Security Operations Center</Link></li>
                                            <li className='main-li li-3'><Link to="/global_frameworks" className='long-a main-a'>Global Frameworks Implementation</Link></li>
                                            <li className='main-li li-4'><Link to="/baseline_maturity" className='long-a main-a'>Baseline & Maturity Assessment</Link></li>
                                            <li className='main-li li-5'><Link to="/service_desk" className='long-a main-a'>Service Desk Deployment & Management</Link></li>
                                            <li className='main-li li-6'><Link to="/business_continuity" className='long-a main-a'>Business Continuity & Disaster Recovery</Link></li>
                                            <li className='main-li li-7'><Link to="/business_process" className='long-a main-a'>Business Process Re-engineering</Link></li>
                                            <li className='main-li li-8'><Link to="/digital_transformation" className='long-a main-a'>Digital Transformation Experience</Link></li>
                                            <li className='main-li li-9'><Link to="/ict_audits" className='long-a main-a'>ICT Audits</Link></li>
                                            <li className='main-li li-10'><Link to="/iso_20000" className='long-a main-a'>ISO 20000</Link></li>
                                            <li className='main-li li-11'><Link to="/project_advisory" className='long-a main-a'>Project Advisory Services</Link></li>
                                            <li className='main-li li-12'><Link to="/agile_coaching" className='long-a main-a'>Agile Coaching</Link></li>
                                            <li className='main-li li-12'><Link to="/vulnerability" className='long-a main-a'>Vulnerability Assessment & Patch Management</Link></li>
                                            <li className='main-li li-12'><Link to="/pentest" className='long-a main-a'>Penetration Testing</Link></li>
                                            <li className='main-li li-13'><Link to="/implement_iso27001" className='long-a main-a'>ISO 27001</Link></li>


                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li className='courses-li trainings-li' onMouseEnter={dropTrainingsActive} onMouseLeave={dropTrainingsInactive}><Link to="/trainings" className='major-a'>Trainings</Link>

                                <div className="dropdown-container" onMouseEnter={dropTrainingsActive} onMouseLeave={dropTrainingsInactive}>
                                    <div className="dropdown-wrapper">
                                        <ul className="main-ul">
                                            <li className='main-li'><Link to="/trainings" className='long-a main-a'>All courses</Link></li>
                                            <li className='main-li li-2' onMouseEnter={() => dropSubActive('li-2')} onMouseLeave={() => dropSubInactive('li-2')}><a className='long-a main-a'>IT Service Management</a>

                                                <div className="sub-dropdown">
                                                    <ul className="sub-ul">
                                                        <li><Link to="/itil_foundation">ITIL 4 Foundation</Link></li>
                                                        <li><Link to="/itil_cds">ITIL 4 Specialist Create, Deliver and Support</Link></li>
                                                        <li><Link to="/itil_dsv">ITIL 4 Specialist Drive Stakeholder Value</Link></li>
                                                        <li><Link to="/itil_hvit">ITIL 4 Specialist High Velocity IT</Link></li>
                                                        <li><Link to="/itil_dpi">ITIL 4 Strategist Direct Plan & Improve</Link></li>
                                                        <li><Link to="/itil_dits">ITIL 4 Leader: Digital & IT Strategy</Link></li>

                                                    </ul>
                                                </div>
                                            </li>
                                            <li className='main-li li-3' onMouseEnter={() => dropSubActive('li-3')} onMouseLeave={() => dropSubInactive('li-3')}><a className='long-a main-a'>Information Security</a>

                                                <div className="sub-dropdown">
                                                    <ul className="sub-ul">
                                                        <li><Link to="/cscu" className='sub-a'>Certified Secure Computer User (CSCU)</Link></li>
                                                        <li><Link to="/ceh" className='sub-a'>Cerified Ethical Hacker</Link></li>
                                                        <li><Link to="/cisa" className='sub-a'>Certified Information Systems Auditor</Link></li>
                                                        <li><Link to="/cism" className='sub-a'>Certified Information Security Manager</Link></li>
                                                        <li><Link to="/iso_27001" className='sub-a'>ISO27001 Foundation</Link></li>
                                                        <li><Link to="/iso_27001_implementer" className='sub-a'>ISO27001 Implementer</Link></li>
                                                        <li><Link to="/iso_27001_auditor" className='sub-a'>ISO27001 Auditor</Link></li>
                                                    </ul>
                                                </div>

                                            </li>
                                            <li className='main-li li-4' onMouseEnter={() => dropSubActive('li-4')} onMouseLeave={() => dropSubInactive('li-4')}><a className='long-a main-a'>IT Governance</a>

                                                <div className="sub-dropdown">
                                                    <ul className="sub-ul">
                                                        <li><Link to="/cobit_foundation" className='sub-a'>COBIT5/2019 Foundation</Link></li>
                                                        <li><Link to="/cobit_implementer" className='sub-a'>COBIT5/2019 Implementation</Link></li>
                                                        <li><Link to="/cobit_assessor" className='sub-a'>COBIT5/2019 Assesor</Link></li>
                                                        <li><Link to="/cgeit" className='sub-a'>Certified in the Governance of Enterprise IT (CGEIT) Course</Link></li>
                                                    </ul>
                                                </div>
                                            </li>

                                            <li className='main-li li-5' onMouseEnter={() => dropSubActive('li-5')} onMouseLeave={() => dropSubInactive('li-5')}><a className='long-a main-a'>Program Management</a>
                                                <div className="sub-dropdown">
                                                    <ul className="sub-ul">
                                                        <li><Link to="/prince2_foundation">PRINCE2 Agile Foundation</Link></li>
                                                        <li><Link to="/prince2_practitioner">PRINCE2 Agile Practitioner</Link></li>
                                                    </ul>
                                                </div>
                                            </li>

                                            <li className='main-li li-6' onMouseEnter={() => dropSubActive('li-6')} onMouseLeave={() => dropSubInactive('li-6')}><a className='long-a main-a'>Business Analysis/App Dev</a>
                                                <div className="sub-dropdown">
                                                    <ul className="sub-ul">
                                                        <li><Link to="/cbap">Certified Business Analysis Professional</Link></li>
                                                        <li><Link to="/devops">DevOps Fundamentals</Link></li>
                                                        <li><Link to="/devops_leadership">DevOps Leadership</Link></li>
                                                        <li><Link to="/scrum_1">Scrum Master I</Link></li>
                                                        <li><Link to="/scrum_2">Scrum Master II</Link></li>

                                                    </ul>
                                                </div>
                                            </li>

                                            <li className='main-li li-7' onMouseEnter={() => dropSubActive('li-7')} onMouseLeave={() => dropSubInactive('li-7')}><a className='long-a main-a'>Enterprise Architecture</a>
                                                <div className="sub-dropdown">
                                                    <ul className="sub-ul">
                                                        <li><Link to="/togaf">TOGAF 9.0 </Link></li>

                                                    </ul>
                                                </div>
                                            </li>

                                            <li className='main-li li-8' onMouseEnter={() => dropSubActive('li-8')} onMouseLeave={() => dropSubInactive('li-8')}><a className='long-a main-a'>Business Continuity</a>
                                                <div className="sub-dropdown">
                                                    <ul className="sub-ul">
                                                        <li><Link to="/iso22301">ISO 22301 – (BCMS) Lead Auditor</Link></li>
                                                        <li><Link to="/iso22301_implementer">ISO 22301 – (BCMS) Lead Implementer</Link></li>
                                                    </ul>
                                                </div>
                                            </li>

                                            <li className='main-li li-9' onMouseEnter={() => dropSubActive('li-9')} onMouseLeave={() => dropSubInactive('li-9')}><a className='long-a main-a'>Software Testing</a>
                                                <div className="sub-dropdown">
                                                    <ul className="sub-ul">
                                                        <li><Link to="/istqb_foundation">ISTQB Certified Tester – Foundation Level (CTFL)</Link></li>
                                                        <li><Link to="/istqb_agile">ISTQB Certified Tester Foundation Level – Agile Tester (CTFL- AT)</Link></li>
                                                        <li><Link to="/istqb_advanced">ISTQB Certified Tester Advanced Level – Test Manager (CTAL-TM)</Link></li>
                                                    </ul>
                                                </div>
                                            </li>

                                            <li className='main-li li-10' onMouseEnter={() => dropSubActive('li-10')} onMouseLeave={() => dropSubInactive('li-10')}><a className='long-a main-a'>Risk Management</a>
                                                <div className="sub-dropdown">
                                                    <ul className="sub-ul">
                                                        <li><Link to="/crisc">CRISC (Certified in Risk and Information Systems Control)</Link></li>
                                                        <li><Link to="/iso31000">IS031000 Foundation</Link></li>
                                                    </ul>
                                                </div>
                                            </li>

                                            <li className='main-li li-11' onMouseEnter={() => dropSubActive('li-11')} onMouseLeave={() => dropSubInactive('li-11')}><a className='long-a main-a'>Data Protection</a>
                                                <div className="sub-dropdown">
                                                    <ul className="sub-ul">
                                                        <li><Link to="/gdpr">GDPR — Certified Data Protection Officer</Link></li>

                                                    </ul>
                                                </div>
                                            </li>

                                            <li className='main-li li-12' onMouseEnter={() => dropSubActive('li-12')} onMouseLeave={() => dropSubInactive('li-12')}><a className='long-a main-a'>Business Transformation</a>
                                                <div className="sub-dropdown">
                                                    <ul className="sub-ul">
                                                        <li><Link to="/agile_fundamentals">Agile Fundamentals</Link></li>
                                                        <li><Link to="/csm">Certified Scrum Master</Link></li>
                                                        <li><Link to="/leading_safe">Leading SAFe</Link></li>
                                                        <li><Link to="/implementing_safe">Implementing SAFe</Link></li>

                                                    </ul>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            </li>
                            <li><Link to="https://blog.ict-mentors.com/" className='major-a'>Blog</Link></li>
                            <li><Link to="/work_with_us" className='major-a'>WorkWithUs</Link></li>
                            <li><Link to="/contact_us" className='major-a'>Contact</Link></li>

                        </ul>
                    </div>

                    <div className="soc-burger">

                        <button className='soc-btn' onClick={() => {navigate('/offers') ; ReactGa.event("click_offer_nav")}}>
                            <img src={goldBell} alt="Offers" loading='lazy' />
                            <span>Exclusive Deals</span>
                        </button>


                        <svg className="burger" onClick={burgerToggle} width="55" height="29" viewBox="0 0 55 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect x="5.96289" width="48.3333" height="5.37037" rx="2.68519" fill="currentColor" />
                            <rect x="0.592773" y="11.8148" width="48.3333" height="5.37037" rx="2.68519" fill="#011B25" />
                            <rect x="5.96289" y="23.6296" width="48.3333" height="5.37037" rx="2.68519" fill="#011B25" />
                        </svg>
                    </div>


                </div>

                <div style={{ position: "relative", width: "100%" }}>
                    <div className="ul-div">
                        <div className="ul-div-container">
                            <ul>
                                <Link to="/"><li>Home</li></Link>
                                <Link to="/about"><li>About</li></Link>
                                <Link to="/solutions"><li>Solutions</li></Link>
                                <Link to="/trainings"><li>Trainings</li></Link>
                                <Link to="https://blog.ict-mentors.com/"><li>Blog</li></Link>
                                <Link to="/work_with_us"><li>WorkWithUs</li></Link>
                                <Link to="/contact_us"><li>Contact</li></Link>
                            </ul>
                        </div>
                    </div>
                </div>


            </nav>
        </ThemeProvider>
    )
}

export default Nav