import { useState, useEffect } from 'react'
import "./Contact.css"
import Nav2 from '../nav2/Nav2'
import maleStaff from "../../images/male-support.png"
import femaleStaff from "../../images/female-support.png"
import phone from "../../images/phone.svg"
import fancyMail from "../../images/fancy-mail.png"
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import phoneBooth from "../../images/phone-booth.png"
import phoneBoothSmall from "../../images/phone-booth-small.png"
import { Button } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton';
import TextField from '@mui/material/TextField';
import SendRoundedIcon from '@mui/icons-material/SendRounded';
import funkyLines from "../../images/funky-lines.png"
import { useDispatch } from "react-redux"
import axios from "axios"
import MyLocationRoundedIcon from '@mui/icons-material/MyLocationRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import placeholder from "../../images/solution-hero-small.png"
import { Helmet } from "react-helmet";
import favicon from "../../images/ict-icon.png"
import ReactGa from "react-ga4"




const Contact = () => {

    // STATES

    const [nameCall, setNameCall] = useState('')
    const [subjectCall, setSubjectCall] = useState('')
    const [phoneCall, setPhoneCall] = useState('')

    const [nameMsg, setNameMsg] = useState('')
    const [emailMsg, setEmailMsg] = useState('')
    const [subjectMsg, setSubjectMsg] = useState('')
    const [msg, setMsg] = useState('')

    const [loading, setLoading] = useState(false)

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////


    // INITIALIZATIONS

    const dispatch = useDispatch()

    //////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    // GET COOKIE FUNCTION

    const getCookie = (name) => {
        // Split cookie string and get all individual name=value pairs in an array
        var cookieArr = document.cookie.split(";");

        // Loop through the array elements
        for (var i = 0; i < cookieArr.length; i++) {
            var cookiePair = cookieArr[i].split("=");

            /* Removing whitespace at the beginning of the cookie name
            and compare it with the given string */
            if (name == cookiePair[0].trim()) {
                // Decode the cookie value and return
                return decodeURIComponent(cookiePair[1]);
            }
        }
    }

    /////////////////////////////////////////

    const csrftoken = getCookie('csrftoken');



    // USEEFFECTS

    // INTERSECTION OBSERVERS

    useEffect(() => {
        const appearLefts = document.querySelectorAll('.appear-left')
        const appearRights = document.querySelectorAll('.appear-right')


        const appearLeftOptions = {
            threshold: 0.2,
        }

        const appearLeftObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearLeftObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearLeftOptions)

        appearLefts.forEach(appearLeft => {
            appearLeftObserver.observe(appearLeft)
        })


        const appearRightOptions = {
            threshold: 0.2,
        }

        const appearRightObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    entry.target.classList.add('active')
                    appearRightObserver.unobserve(entry.target)
                } else {
                    return
                }
            })
        }, appearRightOptions)

        appearRights.forEach(appearRight => {
            appearRightObserver.observe(appearRight)
        })


    }, [])

    /////////////////////////////////////////////////////////////////


    // GET URL PARAMS FOR SUBJECT

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const subject = queryParameters.get("subject")

        if (subject) {
            setSubjectCall(subject)
            setSubjectMsg(subject)
        }
    }, [])

    //////////////////////////////////////////////////////////////////

    // LAZY LOAD USEEFFECT

    useEffect(() => {
        const blurDivs = document.querySelectorAll('.blur-div')


        blurDivs.forEach(div => {
            let img = div.querySelector('img')

            const loaded = () => {
                div.classList.add('loaded')
            }

            if (img.complete) {
                loaded()
            } else {
                img.addEventListener("load", loaded)
            }
        })
    }, [])


    /////////////////////////////////////////////////////////////////

    // LOAD DISAPPEAR USEEFFECT

    useEffect(() => {
        const loader = document.querySelector('.loader-wrapper')

        function vanish() {
            loader.classList.add('disappear')
        }


        window.addEventListener('load', vanish)
    }, [])


    ///////////////////////////////////////////////////////////////////////////////////////////////



    ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    // FUNCTIONS

    const changeCallForm = () => {
        const div1 = document.querySelector('.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card.card1 .div-wrapper .div.div1')
        const div2 = document.querySelector('.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card.card1 .div-wrapper .div2')

        const div1Card2 = document.querySelector('.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card.card2 .div-wrapper .div.div1')
        const div2Card2 = document.querySelector('.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card.card2 .div-wrapper .div2')



        div1.classList.add('deactivate')
        div2.classList.add('activate')

        div1Card2.classList.remove('deactivate')
        div2Card2.classList.remove('activate')



    }

    const changeMsgForm = () => {
        const div1 = document.querySelector('.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card.card2 .div-wrapper .div.div1')
        const div2 = document.querySelector('.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card.card2 .div-wrapper .div2')

        const div1Card1 = document.querySelector('.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card.card1 .div-wrapper .div.div1')
        const div2Card1 = document.querySelector('.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card.card1 .div-wrapper .div2')

        div1Card1.classList.remove('deactivate')
        div2Card1.classList.remove('activate')


        div1.classList.add('deactivate')
        div2.classList.add('activate')

    }

    const closeMsgForm = () => {
        const div1 = document.querySelector('.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card.card1 .div-wrapper .div.div1')
        const div2 = document.querySelector('.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card.card1 .div-wrapper .div2')

        const div1Card2 = document.querySelector('.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card.card2 .div-wrapper .div.div1')
        const div2Card2 = document.querySelector('.contact-container .contact-wrapper .contact-left .cards-wrapper .single-card.card2 .div-wrapper .div2')


        div1Card2.classList.remove('deactivate')
        div2Card2.classList.remove('activate')
    }



    // Contact Fucntions

    const onBeforeCallSubmit = (e) => {
        e.preventDefault()



        if (nameCall.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your name"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (subjectCall.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter the subject of the call"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (phoneCall.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your phone number"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else {
            setLoading(true)

            const payload = {
                "name": nameCall,
                "subject": subjectCall,
                "phone": phoneCall,
            }

            onSubmitPhone(payload)
        }




    }


    const onBeforeMsgSubmit = (e) => {
        e.preventDefault()

        if (nameMsg.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your name"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (emailMsg.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your email address"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (subjectMsg.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter the subject of your message"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else if (msg.length == 0) {
            const message = {
                type: 'info',
                message: "Kindly enter your message"
            }
            dispatch({
                type: 'GET_MESSAGES',
                payload: message
            })
        } else {
            setLoading(true)

            const payload = {
                "name": nameMsg,
                "email": emailMsg,
                "subject": subjectMsg,
                "msg": msg,
            }

            onSubmitMsg(payload)
        }


    }



    const onSubmitPhone = (payload) => {

        window.dataLayer.push({ event: 'inquiry_submission' })

        ReactGa.event("inquiry_submission", { form_id: "Contact Phone Form" })

        axios.post("http://localhost:8000/api/send_mail_phone", payload, { headers: { 'X-CSRFToken': csrftoken } })
            .then(res => {

                dispatch({
                    type: "GET_MESSAGES",
                    payload: res.data
                })

                setNameCall('')
                setSubjectCall('')
                setPhoneCall('')

                setLoading(false)
            })
            .catch(err => {


                const error = {
                    message: err.response.data,
                    status: err.response.status
                }

                dispatch({
                    type: 'GET_ERRORS',
                    payload: error
                })

                setLoading(false)

            })


        // Whatsapp Headers
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer EAAE6t5VJQ3kBO5i9ZAfPDeuu6KfRlPp6UXJXA7sRVv9IyK12I5NlBgQP1lrjoN64P2pF5q3EO0m2XARA7wYOGI16vZAYObEK4XR7MPwv8ZASSAlRtzvHGbzSxNZAcb1Qv2t5hfExi0Hp7lqPZA00rQMo2OUXc4k7X3rjn3TYzf3KnwM2JoHhfECkxGAZCmN5OE'
            }
        }




        // Mutiso Msg
        const waMsg = {
            "messaging_product": "whatsapp",
            "recipient_type": "individual",
            "to": "254759161361",
            "type": "text",
            "text": {
                "preview_url": false,
                "body": `Customer wants you to call them\nName: ${nameCall} \nSubject: ${subjectCall}\nPhone: ${phoneCall} `
            }
        }

        // John Msg
        const waMsg2 = {
            "messaging_product": "whatsapp",
            "recipient_type": "individual",
            "to": "254721574254",
            "type": "text",
            "text": {
                "preview_url": false,
                "body": `Customer wants you to call them\nName: ${nameCall} \nSubject: ${subjectCall}\nPhone: ${phoneCall}`
            }
        }



        // Send Me Mutiso a notification
        axios.post('https://graph.facebook.com/v17.0/174669262396800/messages', waMsg, headers)
            .then(res => {
            })
            .catch(err => {


            })


        // Send John a notification
        axios.post('https://graph.facebook.com/v17.0/174669262396800/messages', waMsg2, headers)
            .then(res => {
            })
            .catch(err => {


            })
    }


    const onSubmitMsg = (payload) => {

        window.dataLayer.push({ event: 'inquiry_submission' })

        ReactGa.event("inquiry_submission", { form_id: "Contact Msg Form" })


        axios.post("http://localhost:8000/api/send_mail_msg", payload, { headers: { 'X-CSRFToken': csrftoken } })
            .then(res => {

                dispatch({
                    type: "GET_MESSAGES",
                    payload: res.data
                })

                setNameMsg('')
                setEmailMsg('')
                setSubjectMsg('')
                setMsg('')

                setLoading(false)
            })
            .catch(err => {
                const error = {
                    message: err.response.data,
                    status: err.response.status
                }

                dispatch({
                    type: 'GET_ERRORS',
                    payload: error
                })
                setLoading(false)
            })


        // Whatsapp Headers
        const headers = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer EAAE6t5VJQ3kBO5i9ZAfPDeuu6KfRlPp6UXJXA7sRVv9IyK12I5NlBgQP1lrjoN64P2pF5q3EO0m2XARA7wYOGI16vZAYObEK4XR7MPwv8ZASSAlRtzvHGbzSxNZAcb1Qv2t5hfExi0Hp7lqPZA00rQMo2OUXc4k7X3rjn3TYzf3KnwM2JoHhfECkxGAZCmN5OE'
            }
        }

        // Mutiso Msg
        const waMsg = {
            "messaging_product": "whatsapp",
            "recipient_type": "individual",
            "to": "254759161361",
            "type": "text",
            "text": {
                "preview_url": false,
                "body": `Customer has reached out with a query\nName: ${nameMsg} \nEmail: ${emailMsg}\nSubject: ${subjectMsg} \nThe Message: ${msg}`
            }
        }

        // John Msg
        const waMsg2 = {
            "messaging_product": "whatsapp",
            "recipient_type": "individual",
            "to": "254721574254",
            "type": "text",
            "text": {
                "preview_url": false,
                "body": `Customer has reached out with a query\nName: ${nameMsg} \nEmail: ${emailMsg}\nSubject: ${subjectMsg} \nThe Message: ${msg}`
            }
        }



        // Send Me Mutiso a notification
        axios.post('https://graph.facebook.com/v17.0/174669262396800/messages', waMsg, headers)
            .then(res => {
            })
            .catch(err => {


            })


        // Send John a notification
        axios.post('https://graph.facebook.com/v17.0/174669262396800/messages', waMsg2, headers)
            .then(res => {
            })
            .catch(err => {


            })


    }



    ///////////////////////////////////////

    const goWhatsapp = () => {
        window.dataLayer.push({ event: 'chat_whatsapp' })

        ReactGa.event("chat_whatsapp")

        window.open('https://wa.me/254741184039?text=Hi%20there.', '_blank')
    }



    // ZOHO WEBFORMS FUNCTIONS

    function checkMandatory5026379000002146016() {
        var mndFileds = new Array('Last Name', 'Email', 'Phone', 'LEADCF4');
        var fldLangVal = new Array('Name', 'Email', 'Phone', 'Message');
        for (let i = 0; i < mndFileds.length; i++) {
            var fieldObj = document.forms['WebToLeads5026379000002146016'][mndFileds[i]];
            if (fieldObj) {
                if (((fieldObj.value).replace(/^\s+|\s+$/g, '')).length == 0) {
                    if (fieldObj.type == 'file') {
                        alert('Please select a file to upload.');
                        fieldObj.focus();
                        return false;
                    }
                    alert(fldLangVal[i] + ' cannot be empty.');
                    fieldObj.focus();
                    return false;
                } else if (fieldObj.nodeName == 'SELECT') {
                    if (fieldObj.options[fieldObj.selectedIndex].value == '-None-') {
                        alert(fldLangVal[i] + ' cannot be none.');
                        fieldObj.focus();
                        return false;
                    }
                } else if (fieldObj.type == 'checkbox') {
                    if (fieldObj.checked == false) {
                        alert('Please accept  ' + fldLangVal[i]);
                        fieldObj.focus();
                        return false;
                    }
                }
                try {
                    if (fieldObj.name == 'Last Name') {
                        let name = fieldObj.value;
                    }
                } catch (e) { }
            }
        }
        var urlparams = new URLSearchParams(window.location.search);
        if (urlparams.has('service') && (urlparams.get('service') === 'smarturl')) {
            var webform = document.getElementById('webform');
            var service = urlparams.get('service');
            var smarturlfield = document.createElement('input');
            smarturlfield.setAttribute('type', 'hidden');
            smarturlfield.setAttribute('value', service);
            smarturlfield.setAttribute('name', 'service');
            webform.appendChild(smarturlfield);
        }
        document.querySelector('.formsubmit').setAttribute('disabled', true);
    }

    function checkMandatory5026379000002174011() {
        var mndFileds = new Array('Last Name', 'Phone', 'LEADCF1');
        var fldLangVal = new Array('Name', 'Phone', 'Subject');
        for (let i = 0; i < mndFileds.length; i++) {
            var fieldObj = document.forms['WebToLeads5026379000002174011'][mndFileds[i]];
            if (fieldObj) {
                if (((fieldObj.value).replace(/^\s+|\s+$/g, '')).length == 0) {
                    if (fieldObj.type == 'file') {
                        alert('Please select a file to upload.');
                        fieldObj.focus();
                        return false;
                    }
                    alert(fldLangVal[i] + ' cannot be empty.');
                    fieldObj.focus();
                    return false;
                } else if (fieldObj.nodeName == 'SELECT') {
                    if (fieldObj.options[fieldObj.selectedIndex].value == '-None-') {
                        alert(fldLangVal[i] + ' cannot be none.');
                        fieldObj.focus();
                        return false;
                    }
                } else if (fieldObj.type == 'checkbox') {
                    if (fieldObj.checked == false) {
                        alert('Please accept  ' + fldLangVal[i]);
                        fieldObj.focus();
                        return false;
                    }
                }
                try {
                    if (fieldObj.name == 'Last Name') {
                        let name = fieldObj.value;
                    }
                } catch (e) { }
            }
        }
        var urlparams = new URLSearchParams(window.location.search);
        if (urlparams.has('service') && (urlparams.get('service') === 'smarturl')) {
            var webform = document.getElementById('webform');
            var service = urlparams.get('service');
            var smarturlfield = document.createElement('input');
            smarturlfield.setAttribute('type', 'hidden');
            smarturlfield.setAttribute('value', service);
            smarturlfield.setAttribute('name', 'service');
            webform.appendChild(smarturlfield);
        }
        document.querySelector('.formsubmit').setAttribute('disabled', true);
    }




    /////////////////////////////////////////////////////////////////////////


    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////



    return (
        <div className='contact-container' style={{ background: `url(${funkyLines}), repeat`, backgroundSize: "200px" }}>
            <Helmet>
                <title>ICT Mentors | Contact us</title>
                <link rel="icon" type="image/png" sizes="16x16" href={favicon} />
            </Helmet>

            <Nav2 />

            <div className="contact-wrapper">

                <div className="contact-left">
                    <h1 className="contact-title appear-left">Contact us</h1>

                    <div className="cards-wrapper">
                        <div className="single-card card1 appear-left">
                            <img src={femaleStaff} alt="" className="staff-img" loading='lazy' />

                            <div className="div-wrapper">
                                <div className="div div1">
                                    <p className="card-p">If you’d like to talk to a member of our team, we’d love to reach out to you.</p>

                                    <Button variant='contained' className='contact-btn' onClick={changeCallForm}>
                                        <span>Give Me a Call</span>
                                        <img src={phone} alt="" className='phone-icon' loading='lazy' />
                                    </Button>
                                </div>

                                <div className="div div2">

                                    <h3 className="form-title">We'll Call You</h3>

                                    <form id='webform' action='https://crm.zoho.com/crm/WebToLeadForm' name='WebToLeads5026379000002174011' method='POST'
                                        onSubmit={() => { return checkMandatory5026379000002174011() }}
                                        accept-charset='UTF-8'>


                                        <input type='text' style={{ display: 'none' }} name='xnQsjsdp'
                                            value='42fbd0a922fb83fe86b38839c46ad9df7323eb2b6f72eb79f6caf24952510eb1'/>
                                        <input type='hidden' name='zc_gad' id='zc_gad' value=''/>
                                        <input type='text' style={{ display: 'none' }} name='xmIwtLD'
                                            value='ca18cc7d7bdc868dd72867afd4264597e25131e2e1bdb26f787cec74550d5cbf144a755537f4912e3d6bc003ea5590ee'/>
                                        <input type='text' style={{ display: 'none' }} name='actionType' value='TGVhZHM='/>
                                        <input type='text' style={{ display: 'none' }} name='returnURL'
                                            value='https&#x3a;&#x2f;&#x2f;www.ict-mentors.com&#x2f;contact_us'/>


                                        <TextField label="Name" type='text' id='Last_Name' name='Last Name' className='form-field' variant="outlined" />
                                        <TextField label="Subject" type='text' id='LEADCF1' name='LEADCF1' className='form-field' variant="outlined" />
                                        <TextField label="Phone" type='number' id='Phone' name='Phone' className='form-field' variant="outlined" />

                                        <LoadingButton
                                            className='formsubmit zcwf_button'
                                            type='submit' variant='contained'
                                            id='formsubmit'
                                            color='primary'
                                            style={{ color: "#ffffff" }}
                                            endIcon={<SendRoundedIcon className='send-icon' />}
                                            loading={loading}
                                            loadingPosition="end"
                                        >
                                            <span>Submit</span>

                                        </LoadingButton>

                                    </form>

                                    <p className="disclaimer">** Rest assured that any contact or details you provide, will be kept strictly confidential **</p>
                                </div>


                            </div>
                        </div>

                        <div className="single-card card2 appear-right">
                            <img src={maleStaff} alt="" className="staff-img" loading='lazy' />

                            <div className="div-wrapper">
                                <div className="div div1">
                                    <p className="card-p">If you’d like to send us a message. We’d love to hear from you.</p>

                                    <Button className='contact-btn' onClick={changeMsgForm}>
                                        <span>Shoot us a Message</span>
                                        <img src={fancyMail} alt="" loading='lazy' />
                                    </Button>
                                    <p className="card-p" style={{ marginTop: "1em" }}>Or</p>
                                    <Button className='contact-btn whatsapp-btn' id='chat-whatsapp' onClick={goWhatsapp} style={{ background: "green" }}>
                                        <span>Chat on Whatsapp</span>
                                        <WhatsAppIcon />
                                    </Button>
                                </div>

                                <div className="div div2">

                                    <h3 className="form-title">Message Us</h3>

                                    <form id='webform' action='https://crm.zoho.com/crm/WebToLeadForm' name='WebToLeads5026379000002146016' method='POST'
                                        onSubmit={() => { return checkMandatory5026379000002146016() }}
                                        accept-charset='UTF-8'>

                                        <input type='text' style={{ display: 'none' }} name='xnQsjsdp'
                                            value='54baf980bd8b7b2bad7e205ba8b8c5cc42e78112626409a251eaeeb720eb90cc' />
                                        <input type='hidden' name='zc_gad' id='zc_gad' value='' />
                                        <input type='text' style={{ display: 'none' }} name='xmIwtLD'
                                            value='021bf0700721bd85cedeeffe16a3bbdf8395466d8807861d13064877db0d1506aa2c575d733764fbf8a94cca98b3e69e' />
                                        <input type='text' style={{ display: 'none' }} name='actionType' value='TGVhZHM=' />
                                        <input type='text' style={{ display: 'none' }} name='returnURL'
                                            value='https&#x3a;&#x2f;&#x2f;www.ict-mentors.com&#x2f;contact_us' />


                                        <TextField label="Name" type='text' id='Last_Name' name='Last Name' className='form-field' variant="outlined" />
                                        <TextField label="Email" type='email' id='Email' name='Email' className='form-field' variant="outlined" />
                                        <TextField label="Subject" type='text' id='LEADCF1' name='LEADCF1' className='form-field' variant="outlined" />
                                        <TextField label="Message" type='text' id='LEADCF4' multiline rows={4} variant="outlined" name='LEADCF4' />

                                        <LoadingButton
                                            className='formsubmit zcwf_button'
                                            type='submit' variant='contained'
                                            id='formsubmit'
                                            color='primary'
                                            style={{ color: "#ffffff" }}
                                            endIcon={<SendRoundedIcon className='send-icon' />}
                                            loading={loading}
                                            loadingPosition="end"
                                        >
                                            <span>Submit</span>

                                        </LoadingButton>

                                    </form>

                                    <p className="disclaimer">** Rest assured that any contact or details you provide, will be kept strictly confidential **</p>
                                    <p className="disclaimer" style={{ color: "green", cursor: "pointer" }} onClick={closeMsgForm}>Chat Whatsapp</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="contact-right">
                    <div className="img-container blur-div appear-right" style={{ backgroundImage: `url(${phoneBoothSmall})`, backgroundSize: "cover", backgroundPosition: 'center' }}>
                        <img src={phoneBooth} alt="" loading='lazy' />
                    </div>
                </div>
            </div>

            <div className="additionals">
                <div className="location">
                    <MyLocationRoundedIcon />
                    <p className="location-p">3rd floor, AAYMCA’s Building State House Crescent, Nairobi</p>
                </div>
                <div className="phone">
                    <LocalPhoneRoundedIcon />
                    <p className="location-p">+254 741 184039</p>
                </div>

                <div className="socials">
                    <a href="https://www.facebook.com/ICTMentorsLtd" target='_blank' className='go-social' id='go-fb' onClick={() => ReactGa.event("go_socials", { social_platform: "Facebook" })}><FacebookRoundedIcon className='fb-icon' /></a>
                    <a href="https://twitter.com/ICTMSL" target='_blank' className='go-social' id='go-twitter' onClick={() => ReactGa.event("go_socials", { social_platform: "Twitter" })}><TwitterIcon className='twitter-icon' /></a>
                    <a href="https://www.linkedin.com/company/ict-mentors-solutions/" target='_blank' className='go-social' id='go-linkedin' onClick={() => ReactGa.event("go_socials", { social_platform: "Linkedin" })}><LinkedInIcon className='linkedin-icon' /></a>
                </div>

                <img src={placeholder} alt="" className='placeholder' />
            </div>

            <p className="copyright-p">Copyright © 2023 ICT Mentors Solutions. All Rights Reserved.</p>


        </div>
    )
}

export default Contact